﻿/* status */
tr.status-pass TD {
    background-color: Crimson;
    color: #fff;

    a {
        color: #fff;
    }
}

tr.status-passwait td {
    color: #fff;
    background-color: DarkGoldenrod;

    a {
        color: #fff;
    }
}

tr.status-certsent td {
    background-color: DimGray;
    color: #fff;

    a {
        color: #fff;
    }
}

tr.status-disabled td {
    background-color: olive;
    color: #fff;

    a {
        color: #fff;
    }
}

.status-key {
    display: flex;
    flex-direction: row;
    @extend .mb-3;
    @extend .d-none;
    @extend .d-sm-flex;

    div {
        width: 80px;
        text-align: center;
        padding: 4px;
        margin-right: 10px;
        color: #fff;
        font-size: 12px;
    }

    .status-pass {
        background-color: Crimson;
    }

    .status-passwait {
        background-color: DarkGoldenrod;
    }

    .status-certsent {
        background-color: DimGray;
    }

    .status-disabled {
        background-color: olive;
    }
}
