﻿.yelp-stars {
    width: 102px;
    height: 18px;
    background: url('/images/yelpstars/regular/regular_5.png') no-repeat;

    &.rating-0 {
        background-image: url('/images/yelpstars/regular/regular_0.png');
    }

    &.rating-1 {
        background-image: url('/images/yelpstars/regular/regular_0.png');
    }

    &.rating-2 {
        background-image: url('/images/yelpstars/regular/regular_1.png');
    }

    &.rating-3 {
        background-image: url('/images/yelpstars/regular/regular_1_half.png');
    }

    &.rating-4 {
        background-image: url('/images/yelpstars/regular/regular_2.png');
    }

    &.rating-5 {
        background-image: url('/images/yelpstars/regular/regular_2_half.png');
    }

    &.rating-6 {
        background-image: url('/images/yelpstars/regular/regular_3.png');
    }

    &.rating-7 {
        background-image: url('/images/yelpstars/regular/regular_3_half.png');
    }

    &.rating-8 {
        background-image: url('/images/yelpstars/regular/regular_4.png');
    }

    &.rating-9 {
        background-image: url('/images/yelpstars/regular/regular_4_half.png');
    }
}
