﻿.form-caption {
    @extend .col-sm-4;
    @extend .col-lg-3;
    @extend .font-weight-bold;
}

div.form-caption {
    @extend .mb-1;
    @extend .mt-1;
}

dt.form-caption {
    font-weight: normal !important;
}

.form-data {
    @extend .col-sm-8;
    @extend .col-lg-9;

    table {
        font-weight: initial;
    }
}

div.form-data {
    @extend .mb-1;
    @extend .mt-1;
}

.form-checkbox {
    @extend .mt-2;
    display: block;

    label {
        display: inline;
        margin-left: 6px;
    }
}

.form-radiobuttonlist {
    @extend .mt-2;
    display: block;

    label {
        display: inline;
        margin-left: 6px;
    }
}

.link-block {
    text-align: center;
    display: block
}

.link-block + .link-block,
.link-block + .btn-block,
.btn-block + .link-block {
    @extend .mt-1;
}

.form-text-error {
    color: red;
    @extend .form-text;
    /* should include form-text*/
}

.form-valid-summary {
    @extend .alert;
    @extend .alert-danger;
}

.form-control-plaintext {
    font-weight: bold;
}

.card-form {
    @extend .card;
    background-color: $gray-100;
    border-color: $gray-200;

    .card-body {
        h4 {
            margin-top: 1rem;
        }

        h4:first-of-type {
            margin-top: auto;
        }
    }
}

.card-form-header {
    @extend .card-header;
    background-color: $gray-200;
    font-size: 1.25rem;
    font-weight: bold;
}

.col-form-label.required {
    font-weight: bold;
}
/* Filters */
.form-group-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: $spacer;
}

.form-group-links {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: $spacer;

    @include media-breakpoint-up(sm) {
        text-align: right;
    }

    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}

.form-group-check {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: $spacer;

    .form-group-check-item {
        label {
            margin-left: 6px;
        }
    }
}

